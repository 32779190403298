import React from "react"
import PropTypes from 'prop-types'

import styles from '@styles/components/post_footer.module.scss'

import TransitionAnimationLink from '@comp/transition_animation_link'
import IcnTriangle from '@comp/atom/icn_triangle'

const propTypes = {
  prevPath: PropTypes.string,
  nextPath: PropTypes.string,
}

const PostFooter = ({
  prevPath,
  nextPath
}) => {
  return (
    <footer className={styles.footer}>
      {
        prevPath ? (
          <div className={styles.btnPrev}>
            <TransitionAnimationLink to={prevPath}>
              <span className={styles.btn__inner}>
                <span className={styles.btn__label}>戻る</span>
                <span className={styles.btn__icn}><IcnTriangle /></span>
              </span>
            </TransitionAnimationLink>
          </div>
        ): null
      }
      {
        nextPath ? (
          <div className={styles.btnNext}>
            <TransitionAnimationLink to={nextPath}>
              <span className={styles.btn__inner}>
                <span className={styles.btn__label}>次へ</span>
                <span className={styles.btn__icn}><IcnTriangle /></span>
              </span>
            </TransitionAnimationLink>
          </div>
        ) : null
      }
    </footer>
  )
}

PostFooter.propTypes = propTypes

export default PostFooter
