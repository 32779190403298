import React,{ useMemo } from "react"
import { graphql } from "gatsby"

import styles from '@styles/template/post.module.scss'
import Layout from "@comp/layout"
import SEO from '@comp/seo'

import BtnBox from '@comp/btn_detail'
import PostFooter from '@comp/post_footer'

export default function Template({
  location,
  data,
}) {
  const { markdownRemark, allMarkdownRemark } = data;
  const { frontmatter, html, id } = markdownRemark
  const { edges } = allMarkdownRemark
  const {
    title,
    description,
    date,
    link,
    linkLabel
  } = frontmatter
  const datetime = useMemo( () => {
    return date.replace(/\//g,'-')
  },[date]);
  
  const postInfo = useMemo( () => {
    return edges.find( ({node}) => {
      return node.id === id
    })
  },[edges, id])

  const prevPath = postInfo.previous?.frontmatter.slug
  const nextPath = postInfo.next?.frontmatter.slug

  return (
    <Layout location={location} pageName="post">
      <SEO
        title={title}
        description={description}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.inner}>
          <header className={styles.header}>
            <h1 className={styles.title}>{ title }</h1>
            <time className={styles.date} dateTime={datetime}>{date}</time>
          </header>

          <div
            className={styles.body} 
            dangerouslySetInnerHTML={{ __html: html }}
          >
          </div>

          { 
            link ?
              <>
                <div className={styles.post__link}>
                  <BtnBox 
                    href={link}
                    target="_blank"
                  >
                    { linkLabel }
                  </BtnBox>
                </div>
              </> : null
          }

          <PostFooter prevPath={prevPath}　nextPath={nextPath} />
        </div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        slug
        title
        link
        linkLabel
      }
    },
    allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___date]}, filter: {frontmatter: {category: {eq: "news"}}}) {
      edges {
        node {
          id
        }
        next {
          frontmatter {
            slug
          }
        }
        previous {
          frontmatter {
            slug
          }
        }
      }
    }
  }
`